import { useModal } from '@bitstopco/bitstop-theme';
import { useState } from 'react';

type Taggable = {
  global_id: string;
  tag_ids: string[];
};

type MultiSelectAction = {
  id: string;
  getProps: (taggable: Taggable[], multiSelectValues: boolean[]) => object;
};

const MULTI_SELECT_CONFIG = [
  {
    label: 'Add Tag',
  },
  {
    label: 'Remove Tag',
  },
];

const getSelectedGlobalIds = (taggable: Taggable[], multiSelectValues: boolean[]) =>
  multiSelectValues
    .map((isSelected, index) => isSelected && taggable[index]?.global_id)
    .filter(Boolean) as string[];

const multiSelectActions: Record<number, MultiSelectAction> = {
  0: {
    id: 'bulk-associate-tags',
    getProps: (data, multiSelectValues) => ({
      selectedGlobalIds: getSelectedGlobalIds(data, multiSelectValues),
    }),
  },
  1: {
    id: 'bulk-unassociate-tags',
    getProps: (data, multiSelectValues) => {
      const selectedGlobalIds = getSelectedGlobalIds(data, multiSelectValues);

      const selectedTags = (
        multiSelectValues
          .map((isSelected, index) => isSelected && data[index]?.tag_ids)
          .filter(Boolean) as string[][]
      ).flat();

      const uniqueSelectedTags = Array.from(new Set(selectedTags));

      return { selectedGlobalIds, uniqueSelectedTags };
    },
  },
};

export const useBulkTaggableActions = (data: Taggable[], category: string) => {
  const { show } = useModal();
  const [multiSelectValues, setMultiSelectValues] = useState<boolean[]>([]);

  const handleMultiSelectActions = (index: number) => {
    const modalAction = multiSelectActions[index] || multiSelectActions[0];
    const modalProps = modalAction.getProps(data, multiSelectValues);
    show(modalAction.id, { ...modalProps, category });
  };

  return {
    multiSelectValues,
    setMultiSelectValues,
    config: MULTI_SELECT_CONFIG,
    handleMultiSelectActions,
  };
};
