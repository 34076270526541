import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import toast from 'react-hot-toast';

import { useDeleteLocationMutation } from '@/store/api/services/locations/location';
import ErrorIcon from '@mui/icons-material/Error';

import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';
import type { TypographyProps } from '@mui/material';

const TypographyStrong = (props: TypographyProps) => (
  <Typography
    {...props}
    fontWeight={600}
    component="strong"
    variant="subtitle2"
    color="text.primary"
  />
);

const DeleteLocation = ({ hide, props }: ModalChildrenProps) => {
  const [deleteMutation, { isLoading }] = useDeleteLocationMutation();
  const atmsCount = (props?.data?.atms ?? []).length;
  const name = props?.data?.location_name;
  const locationID = props?.data?.location_id ?? 0;

  const handleDelete = async () => {
    try {
      await deleteMutation(locationID).unwrap();
      hide();
      props?.onSuccess?.();
      toast.success(`Successfully deleted location: ${name}`);
    } catch (_err) {
      toast.error('Error deleting location');
    }
  };

  return (
    <>
      <DialogContent sx={{ py: 3 }}>
        <Stack gap={2}>
          <Box>
            <Typography variant="h6">
              Are you sure you want to delete location: <b>{name}</b>?
            </Typography>
            <Typography mt={1} variant="body1">
              Deleting this location will remove it from view in Nonce.
            </Typography>
          </Box>
          {atmsCount > 0 && (
            <Alert severity="error" icon={<ErrorIcon />}>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                data-test="delete-tag-resources"
              >
                This <TypographyStrong>{name}</TypographyStrong> location is associated to{' '}
                <TypographyStrong>{atmsCount} machine(s)</TypographyStrong>
              </Typography>
            </Alert>
          )}
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide} data-test="remove-match-back-button">
          Cancel
        </Button>
        <LoadingButton
          color="error"
          variant="contained"
          onClick={handleDelete}
          loading={isLoading}
          data-test="delete-location-submit-button"
          disabled={atmsCount > 0}
        >
          Delete Location
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default DeleteLocation;
