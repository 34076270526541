import { useEffect, useState } from 'react';

import { AutoFields, AutoForm, SubmitField, useForm } from '@bitstopco/bitstop-theme';

import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Popover,
  Stack,
  Typography,
} from '@mui/material';

import SCHEMAS from '@/forms/schemas';

import { DEFAULT_MACHINE_TAG_COLOR, MACHINE_TAG_COLORS } from '@/constants/app/machine';

import type { Tag, UpsertTagModel } from '@/types';

interface TagsUpsertTagProps {
  selectedTag: Partial<Tag>;
  anchorEl: HTMLElement | null;
  onSubmitTagUpsert: (tag: Partial<Tag>) => void;
  onClickDeleteTag?: (tag: Tag) => void;
  onClose: () => void;
}

const TagsUpsertTag = ({
  anchorEl,
  selectedTag: { id, name, color, category },

  onSubmitTagUpsert,
  onClickDeleteTag,
  onClose,
}: TagsUpsertTagProps) => {
  const [selectedColor, setSelectedColor] = useState(color);
  const { schema } = useForm(SCHEMAS.UPSERT_MACHINE_TAG, { initValues: { name } });

  const open = !!anchorEl;

  useEffect(() => {
    if (color) {
      const validColor =
        MACHINE_TAG_COLORS.indexOf(color.toUpperCase()) !== -1 ? color : DEFAULT_MACHINE_TAG_COLOR;
      setSelectedColor(validColor);
    }
  }, [color]);

  const handleSubmit = async ({ name }: UpsertTagModel) => {
    onSubmitTagUpsert({
      id,
      name,
      color: selectedColor,
    });
  };

  const handleDelete = () => {
    onClickDeleteTag?.({ id, name, color, category } as Tag);
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{ sx: { maxWidth: 256 } }}
    >
      <Paper sx={{ px: 2, pt: 0, pb: 1 }} data-test="machine-details-tag-upsert">
        <ClickAwayListener onClickAway={onClose}>
          <AutoForm<UpsertTagModel> showInlineError schema={schema} onSubmit={handleSubmit}>
            <AutoFields />
            <Divider sx={{ mx: -2, my: 1 }} />

            <Box>
              <Typography variant="subtitle2" color="text.secondary">
                Select a color
              </Typography>
              <Box py={1}>
                <Grid container spacing={1.35}>
                  {MACHINE_TAG_COLORS.map((color) => {
                    const isSelected = String(selectedColor).toUpperCase() === color.toUpperCase();
                    return (
                      <Grid item key={`machine-tag-color-${color}`}>
                        <Box
                          component="button"
                          data-test="machine-details-tag-upsert-color"
                          onClick={() => setSelectedColor(color)}
                          width={36}
                          height={32}
                          sx={{
                            display: 'flex',
                            cursor: 'pointer',
                            borderRadius: '4px',
                            alignItems: 'center',
                            backgroundColor: color,
                            justifyContent: 'center',
                            border: '1px solid rgba(80, 97, 118, 0.08)',
                            '&:hover': {
                              backgroundColor: `${color}80`,
                            },
                          }}
                        >
                          {isSelected && <CheckIcon fontSize="small" htmlColor="white" />}
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Box>

            <Divider sx={{ mx: -2, my: 1 }} />
            {/* Justify content depending if its new tag or existing tag */}
            <Stack direction="row" justifyContent={id ? 'space-between' : 'flex-end'}>
              {!!id && (
                <Button size="small" onClick={handleDelete} data-test="tag-delete-btn">
                  Delete tag
                </Button>
              )}
              <SubmitField
                label={
                  <>
                    <Typography variant="caption" mr={1}>
                      Save changes
                    </Typography>
                    <CheckIcon fontSize="small" />
                  </>
                }
                size="small"
                data-test="tag-save-changes-btn"
              />
            </Stack>
          </AutoForm>
        </ClickAwayListener>
      </Paper>
    </Popover>
  );
};

export default TagsUpsertTag;
