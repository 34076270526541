import type { Tag } from '@/types';
import { useModal } from '@bitstopco/bitstop-theme';
import { useCallback, useState } from 'react';

import {
  useCreateTagMutation,
  useCreateTagsResourcesMutation,
  useDeleteTagsResourcesMutation,
  useUpdateTagMutation,
} from '@/store/api/services/common/tags';

const useTags = () => {
  const { show } = useModal();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [createTagsResources] = useCreateTagsResourcesMutation();
  const [deleteTagsResources, { isLoading: isLoadingDeleteTagsResources }] =
    useDeleteTagsResourcesMutation();

  const [createTag] = useCreateTagMutation();
  const [updateTag] = useUpdateTagMutation();

  const openTagsMenu = useCallback((target: HTMLElement) => {
    setAnchorEl(target);
  }, []);

  const closeTagsMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const showDeleteTagModal = (tag: Tag) => {
    show('tag-delete', tag);
  };

  return {
    anchorEl,
    setAnchorEl,
    openTagsMenu,
    closeTagsMenu,
    deleteTagsResources,
    createTagsResources,
    createTag,
    updateTag,
    showDeleteTagModal,
    isLoadingDeleteTagsResources,
  };
};

export default useTags;
