import { getCustomInstance } from '@/helpers/axiosBaseQuery';

export const ergot = getCustomInstance({
  baseURL: '/api/e/',
  headers: ['User-Token'],
  tokenPrefixes: ['Bearer'],
});

export const boro = getCustomInstance({
  baseURL: '/api/b/',
  headers: ['Authorization'],
  tokenPrefixes: ['Bearer'],
});

export const athena = getCustomInstance({
  baseURL: '/api/a/',
  headers: ['Authorization'],
  tokenPrefixes: ['Bearer'],
});

export const walletProvider = getCustomInstance({
  baseURL: '/api/wp/',
  headers: ['Authorization'],
  tokenPrefixes: ['Bearer'],
});

export const operator = getCustomInstance({
  baseURL: '/api/o/',
  headers: ['Authorization'],
  tokenPrefixes: ['Bearer'],
});

//location service v2
export const atlas = getCustomInstance({
  baseURL: '/api/atlas/',
  headers: ['Authorization'],
  tokenPrefixes: ['Bearer'],
});

export const notification = getCustomInstance({
  baseURL: '/api/n/',
  headers: ['Authorization'],
  tokenPrefixes: ['Bearer'],
});

export const persona = getCustomInstance({
  baseURL: '/api/p/',
  headers: ['Authorization'],
  tokenPrefixes: ['Bearer'],
});

export const proxy = getCustomInstance({
  baseURL: '/api/py/',
  headers: ['User-Token'],
  tokenPrefixes: ['Bearer'],
});

export const unity = getCustomInstance({
  baseURL: '/api/u/',
  headers: ['User-Token'],
  tokenPrefixes: ['Bearer'],
});

export const heartbeat = getCustomInstance({
  baseURL: '/api/hb/',
  headers: ['Authorization'],
  tokenPrefixes: ['Bearer'],
});
