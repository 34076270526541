import { TablePageSelector, TableWrapper } from '@bitstopco/bitstop-theme';
import { useModal } from '@bitstopco/bitstop-theme';
import { skipToken } from '@reduxjs/toolkit/query';
import { cloneDeep, omit } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';

import SalesStatsCards from '@/components/ui/SalesStatsCards';
import CardTable from '@/components/ui/card/CardTable';
import { useBulkTaggableActions } from '@/hooks/useBulkTaggableActions';
import { useGetAllTagsByTermQuery } from '@/store/api/services/common/tags';
import {
  useGetTransactionsExportMutation,
  useGetTransactionsQuery,
  useGetTransactionsStatsQuery,
} from '@/store/api/services/transactions/transactions';

import { DEFAULT_PAGINATION } from '@/constants/api';
import {
  SALES_COLUMNS,
  TRANSACTIONS_FILTERS_CONFIG,
  TRANSACTIONS_STATS,
} from '@/constants/app/transactions';

import type { Tag, TransactionsSale, WithTags } from '@/types';
import type {
  ApiListingRequest,
  TablePageSelectorProps,
  TableToolbarFilterConfig,
  TableWrapperRef,
} from '@bitstopco/bitstop-theme';

/**
 * Transactions Sales Table
 * * For tables we have the TableWrapper component from the Bitstop Theme, for more information please visit the Bitstop Theme documentation
 * @return {*}
 */
const TransactionsSales = () => {
  const params = useParams();
  const { show } = useModal();

  const [exportMutation] = useGetTransactionsExportMutation();

  const [payload, setPayload] = useState<ApiListingRequest>();

  const tableRef = useRef<TableWrapperRef>(null);

  const statsPayload = useMemo(
    () => payload && omit(payload as ApiListingRequest, ['pagination']),
    [payload],
  );

  useEffect(() => {
    if (params?.saleId) {
      handleRow({ id: params.saleId } as TransactionsSale);
    }
  }, []);

  /**
   * Get the Transactions filtered by the desired payload
   */
  const {
    data: {
      data: apiData = [],
      included,
      meta: { pagination: metaPagination = DEFAULT_PAGINATION } = {},
    } = {},
    isFetching,
  } = useGetTransactionsQuery(payload || skipToken);

  const { data: tagData } = useGetAllTagsByTermQuery({ category: 'transaction', term: '' });
  const tagsList = useMemo(() => tagData?.data ?? [], [tagData]);

  const data: WithTags<TransactionsSale>[] = useMemo(() => {
    const allTags = (included?.tags ?? []) as Tag[];
    return apiData.map((customer) => ({
      ...customer,
      tags: allTags.filter(({ id }) => customer.tag_ids.includes(id)),
    }));
  }, [apiData]);

  const { multiSelectValues, setMultiSelectValues, handleMultiSelectActions, config } =
    useBulkTaggableActions(data, 'transaction');

  /**
   * Get the Transactions stats filtered by the desired payload
   */
  const {
    data: { data: stats } = {},
    isFetching: isFetchingStats,
  } = useGetTransactionsStatsQuery(statsPayload || skipToken);

  const handleRow = ({ id }: TransactionsSale) => {
    /**
     * Show the machine transaction details modal
     */
    show('transaction-details', {
      data: {
        id,
      },
    });
  };

  const handleExport = (type: string) => {
    // Use the getExportFile helper to export the Table data using the current payload
    tableRef.current?.getExportFile({
      type,
      mutation: exportMutation,
      fileName: 'Nonce-Sales-Report',
    });
  };

  // Custom render Page Selector, this is necessary since we are using a custom toggle
  const renderPageSelector = useCallback(
    (props: TablePageSelectorProps) => (
      <>
        <SalesStatsCards
          stats={stats}
          mapData={TRANSACTIONS_STATS}
          isLoading={isFetchingStats || !statsPayload}
          sx={{ mx: 3, mb: 2 }}
        />
        <TablePageSelector {...props} />
      </>
    ),
    [stats, statsPayload, isFetchingStats],
  );

  const filtersConfig = useMemo(() => {
    const config = cloneDeep(TRANSACTIONS_FILTERS_CONFIG) as Record<string, any>[];
    config[3].options = [
      ...tagsList.map(({ id, name, color }) => ({ label: name, value: id, color })),
    ];
    return config as TableToolbarFilterConfig[];
  }, [tagsList]);

  return (
    <CardTable>
      <TableWrapper<WithTags<TransactionsSale>>
        data={data}
        ref={tableRef}
        dateRangeFilterKey="created_at"
        sortByDefaultValue="created_at"
        isLoading={isFetching || !payload}
        columns={SALES_COLUMNS}
        filterConfigs={filtersConfig}
        tableToolbarProps={{
          searchPlaceholder: 'Search by Sale ID, TX Hash, or Wallet Address',
        }}
        renderPageSelector={renderPageSelector}
        totalPages={metaPagination?.total_pages}
        totalItems={metaPagination?.total_items}
        tableProps={{
          placeholder: {
            empty: {
              title: 'No sales found',
              content:
                "We can't find any sale that matches your search, please try a different request",
            },
          },
        }}
        onChange={setPayload}
        onRowClick={handleRow}
        onActionMenuItemClick={handleExport}
        multiSelectValues={multiSelectValues}
        onMultiSelectValuesChange={setMultiSelectValues}
        tableMultiSelectActionsProps={{
          onClick: handleMultiSelectActions,
          name: 'Location(s)',
          config,
        }}
      />
    </CardTable>
  );
};

export default TransactionsSales;
