import { useEffect, useMemo, useRef, useState } from 'react';

import { Box, Divider, Paper, Popover, Typography } from '@mui/material';
import { useDebounce } from 'use-debounce';

import TagList from '@/components/ui/TagList';
import { useGetAllTagsByTermQuery } from '@/store/api/services/common/tags';

import TagsExistingTags from './TagsExistingTags';
import TagsItem from './TagsItem';
import TagsUpsertTag from './TagsUpsertTag';

import { DEFAULT_MACHINE_TAG_COLOR } from '@/constants/app/machine';

import type { MachinesTag, Tag } from '@/types';
import type { EventTarget } from './TagsItem';

interface TagsMenuProps {
  anchorEl: HTMLElement | null;
  category: string;
  selectedTagIds: string[];
  tagsCollection?: Tag[];
  onClose: () => void;
  onClickAddTag?: (tagId: string) => void;
  onSubmitTagUpsert?: (tag: Partial<Tag>) => void;
  onClickDeleteTagFromList?: (tagId: string) => void;
  onClickDeleteTagFromMenu?: (tag: Tag) => void;
}

const TagsMenu = ({
  anchorEl: selfAnchorEl,
  selectedTagIds,
  tagsCollection,
  category,

  onClose,
  onClickAddTag,
  onSubmitTagUpsert,
  onClickDeleteTagFromList,
  onClickDeleteTagFromMenu,
}: TagsMenuProps) => {
  const refInput = useRef<HTMLInputElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const [value, setValue] = useState('');
  const [term] = useDebounce(value, 300);

  const { data: tagsData, isFetching } = useGetAllTagsByTermQuery({ term, category });
  const data = useMemo(() => tagsData?.data ?? [], [tagsData]);

  const selectedTag = data.find(({ id }) => id === selectedId) || {
    id: undefined,
    name: value,
    color: DEFAULT_MACHINE_TAG_COLOR,
    category,
  };

  useEffect(() => {
    if (!selfAnchorEl) {
      setValue('');
      setAnchorEl(null);
      setSelectedId(null);
    } else {
      setTimeout(() => {
        refInput.current?.focus?.();
      }, 0);
    }
  }, [selfAnchorEl]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleUpsert = (id: string | null, event: EventTarget) => {
    setSelectedId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (closeAll?: boolean) => {
    setAnchorEl(null);
    setTimeout(() => setSelectedId(null), 500);

    closeAll && onClose();
  };

  const handleAddTag = (id: any) => {
    onClickAddTag?.(id);
    onClose();
  };

  const handleTagUpsert = async (tag: Partial<MachinesTag>) => {
    onSubmitTagUpsert?.(tag);
    onClose();
  };

  return (
    <Popover
      open={!!selfAnchorEl}
      anchorEl={selfAnchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      sx={{ marginTop: 1 }}
      PaperProps={{ sx: { maxWidth: 295 } }}
      data-test="machine-details-add-tag-menu"
    >
      <Paper sx={{ px: 2, py: 1 }}>
        <TagList
          selectedTagIds={selectedTagIds}
          tagsCollection={tagsCollection}
          onClickDeleteTag={onClickDeleteTagFromList}
        />

        <Box my={1}>
          <input
            value={value}
            ref={refInput}
            onChange={handleSearch}
            placeholder="Enter name"
            style={{ border: 0, outline: 0 }}
            data-test="tags-menu-input"
          />
        </Box>

        <Divider sx={{ mx: -2, my: 1 }} />
        <Typography variant="subtitle2">Select an existing option or create one</Typography>

        {value !== '' && (
          <TagsItem
            variant="create"
            name={value}
            id="create"
            color={DEFAULT_MACHINE_TAG_COLOR}
            onClick={handleUpsert}
            data-test="machine-details-create-tag"
          />
        )}

        <TagsExistingTags
          data={data}
          loading={isFetching}
          selectedTags={selectedTagIds}
          onClick={handleAddTag}
          onClickEdit={handleUpsert}
        />

        <TagsUpsertTag
          anchorEl={anchorEl}
          selectedTag={selectedTag}
          onClose={handleClose}
          onSubmitTagUpsert={handleTagUpsert}
          onClickDeleteTag={onClickDeleteTagFromMenu}
        />
      </Paper>
    </Popover>
  );
};

export default TagsMenu;
