import { useGetAllTagsByTermQuery } from '@/store/api/services/common/tags';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';
import { LoadingButton } from '@mui/lab';
import toast from 'react-hot-toast';

import { Button, DialogActions, DialogContent, Divider, Stack, Typography } from '@mui/material';

import TagsMenu from '@/components/tags/TagsMenu';
import TagList from '@/components/ui/TagList';
import { MACHINES_BULK_MAX_TAGS_ALLOWED } from '@/constants/app/machine';
import useTags from '@/hooks/useTags';
import type { Tag } from '@/types';
import pluralize from 'pluralize';
import { useMemo, useRef, useState } from 'react';

const BulkAssociateTags = ({ props, hide }: ModalChildrenProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const category = props?.category;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const selectedTagIds = useMemo(() => selectedTags.map(({ id }) => id), [selectedTags]);
  const { data: tagsData } = useGetAllTagsByTermQuery({ term: '', category });
  const tagsList = useMemo(() => tagsData?.data ?? [], [tagsData]);
  const resourceIds = useState<string[]>(props?.selectedGlobalIds)[0];

  const {
    anchorEl,
    openTagsMenu,
    closeTagsMenu,
    showDeleteTagModal,
    createTagsResources,
    updateTag,
    createTag,
  } = useTags();

  const handleOpenTagsMenu = () => openTagsMenu(containerRef.current!);

  const handleAddTag = async () => {
    setIsLoading(true);
    await createTagsResources({ tag_ids: selectedTagIds, resource_ids: resourceIds });
    hide();
    toast.success('The items have been updated with chosen tag');
  };

  const handleSelectTag = (selectedId: string) => {
    const tag = tagsList.find(({ id }) => selectedId === id);
    if (tag) setSelectedTags([tag]);
  };

  const handleUnselectTag = () => {
    setSelectedTags([]);
  };

  const handleTagUpsert = async ({ name, id, color }: Partial<Tag>) => {
    let selectedId: string = id ?? '';
    const model = { name: name!, color: color!, category };
    if (!id) {
      const result = await createTag(model).unwrap();
      selectedId = result.data;
    } else {
      await updateTag({ ...model, id }).unwrap();
    }
    setSelectedTags([{ ...model, id: selectedId }]);
  };

  const subtitle = useMemo(() => {
    const selectedAtmGlobalIdsCount = resourceIds.length;
    const pluralizedTerm = pluralize('item', selectedAtmGlobalIdsCount);

    return `The next changes will be applied to the ${selectedAtmGlobalIdsCount} selected ${pluralizedTerm} in the previous list.`;
  }, [resourceIds]);

  return (
    <>
      <DialogContent sx={{ my: 2 }} data-test="bulk-associate-tags-modal">
        <Typography variant="h6" data-test="bulk-associate-tags-modal-title">
          Bulk edit action
        </Typography>
        <Typography variant="body1" mt={1} data-test="bulk-associate-tags-modal-subtitle">
          {subtitle}
        </Typography>

        <Stack mt={3} gap={0.25}>
          <Typography variant="caption" component="div">
            New Tag
          </Typography>
          <div ref={containerRef}>
            <TagList
              maxTagsAllowed={MACHINES_BULK_MAX_TAGS_ALLOWED}
              selectedTagIds={selectedTagIds}
              tagsCollection={tagsList}
              onClickAddTag={handleOpenTagsMenu}
              onClickDeleteTag={handleUnselectTag}
            />

            <TagsMenu
              category={category}
              anchorEl={anchorEl}
              selectedTagIds={selectedTagIds}
              tagsCollection={tagsList}
              onClose={closeTagsMenu}
              onClickDeleteTagFromMenu={showDeleteTagModal}
              onClickAddTag={handleSelectTag}
              onSubmitTagUpsert={handleTagUpsert}
            />
          </div>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide}>Back</Button>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          disabled={selectedTagIds.length === 0}
          onClick={handleAddTag}
          data-test="bulk-associate-tags-modal-submit-btn"
        >
          Add Tag
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default BulkAssociateTags;
