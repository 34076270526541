import { useMemo, useState } from 'react';

import pluralize from 'pluralize';
import toast from 'react-hot-toast';

import type { AxiosError } from 'axios';

import { Select } from '@bitstopco/bitstop-theme';
import type { ModalChildrenProps, SelectValue } from '@bitstopco/bitstop-theme';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from '@mui/material';

import useTags from '@/hooks/useTags';

import { useGetAllTagsByTermQuery } from '@/store/api/services/common/tags';

const CustomRenderValue = ({ value }: { value?: SelectValue }) => {
  if (!value) {
    return <Typography variant="body2">Select a tag</Typography>;
  }

  const { label, color } = value;

  return (
    <>
      <Chip
        size="small"
        label={label}
        component="span"
        sx={{
          color: 'white',
          cursor: 'pointer',
          backgroundColor: color,
        }}
      />
    </>
  );
};

/**
 * Bulk Unassociate tags Modal
 *
 * @param {ModalChildrenProps} { hide, props }
 * @return {*}
 */
const BulkUnassociateTags = ({ hide, props }: ModalChildrenProps) => {
  const [selectedTagId, setSelectedIdTag] = useState<string | string[]>();

  const uniqueSelectedTags = useState<string[]>(props?.uniqueSelectedTags)[0];
  const selectedGlobalIds = useState<string[]>(props?.selectedGlobalIds)[0];
  const category = props?.category;

  const { deleteTagsResources, isLoadingDeleteTagsResources } = useTags();

  const { data: tagsData } = useGetAllTagsByTermQuery({ term: '', category });
  const tagsList = useMemo(() => tagsData?.data ?? [], []);

  const { selectedAtmTags, selectedTag } = useMemo(() => {
    const selectedAtmTags = tagsList
      .filter(({ id }) => uniqueSelectedTags.includes(id))
      .map(({ id, name, color }) => ({
        value: id,
        label: name,
        color,
      }));

    const selectedTag = selectedAtmTags.find(({ value }) => value === selectedTagId);

    return { selectedAtmTags, selectedTag };
  }, [tagsList, selectedTagId, uniqueSelectedTags]);

  const subtitle = useMemo(() => {
    const selectedAtmGlobalIdsCount = selectedGlobalIds.length;
    const pluralizedTerm = pluralize('item', selectedAtmGlobalIdsCount);

    return `The next changes will be applied to the ${selectedAtmGlobalIdsCount} selected ${pluralizedTerm} in the previous list.`;
  }, [selectedGlobalIds]);

  const handleSubmit = async () => {
    try {
      await deleteTagsResources({
        tag_ids: [selectedTagId as string],
        resource_ids: selectedGlobalIds,
      }).unwrap();

      toast.success('Tag removed successfully');

      hide();
    } catch (error) {
      const { message } = error as AxiosError;

      toast.error(message);
    }
  };

  return (
    <>
      <DialogContent sx={{ my: 2 }}>
        <Typography variant="h6" data-test="bulk-unassociate-tags-modal-title">
          Bulk edit action
        </Typography>
        <Typography variant="body1" mt={1} data-test="bulk-unassociate-tags-modal-subtitle">
          {subtitle}
        </Typography>

        <Stack mt={3} gap={0.25}>
          <Typography variant="caption" component="div">
            Select a tag to remove from selected machines
          </Typography>
          <Box>
            <Select
              value={selectedTag?.value}
              onChange={setSelectedIdTag}
              options={selectedAtmTags}
              multiple={false}
              showFooter
              searchable
              label="Tags"
              applyText="Select tag"
              clearText="Clear selection"
              searchPlaceholder="Search by tag name"
              data-test="bulk-unassociate-tags-modal-select"
              {...{
                renderValue: () => <CustomRenderValue value={selectedTag} />,
              }}
              sx={{
                width: '100%',
              }}
              applyProps={{
                size: 'small',
                color: 'primary',
                variant: 'contained',
              }}
              clearProps={{
                size: 'small',
              }}
            />
          </Box>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide}>Back to machine</Button>
        <LoadingButton
          variant="contained"
          disabled={!selectedTagId}
          loading={isLoadingDeleteTagsResources}
          onClick={handleSubmit}
          data-test="bulk-unassociate-tags-modal-submit-btn"
        >
          Remove selected tag
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default BulkUnassociateTags;
