import { Grid, Typography } from '@mui/material';
import { useRef } from 'react';

import TagsMenu from '@/components/tags/TagsMenu';
import TagList from '@/components/ui/TagList';

import useTags from '@/hooks/useTags';

import type { Tag } from '@/types';

interface DetailsTagsProps {
  tagIds: string[];
  resourceId: string;
  tagsCollection?: Tag[];
  category: string;
}

const SidebarTags = ({ tagIds, resourceId, tagsCollection, category }: DetailsTagsProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    anchorEl,
    openTagsMenu,
    closeTagsMenu,
    showDeleteTagModal,

    deleteTagsResources,
    createTagsResources,

    updateTag,
    createTag,
  } = useTags();

  const handleOpenTagsMenu = () => {
    openTagsMenu(containerRef.current!);
  };

  const handleDeleteTagsResources = (tagId: string) => {
    deleteTagsResources({ tag_ids: [tagId], resource_ids: [resourceId] });
  };

  const handleCreateTagsResources = (tagId: string) => {
    createTagsResources({ tag_ids: [tagId], resource_ids: [resourceId] });
  };

  const handleSubmitTagsUpsert = async (tag: Partial<Tag>) => {
    const { id, color, name } = tag as Tag;

    if (id) {
      updateTag({ id, name, color, category });
      return;
    }

    const { data } = await createTag({ name, color, category }).unwrap();

    createTagsResources({
      tag_ids: [data],
      resource_ids: [resourceId],
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <Typography variant="subtitle2">Tags</Typography>
      </Grid>
      <Grid ref={containerRef} item xs={10} data-test="machine-details-details-card-tags">
        <TagList
          selectedTagIds={tagIds}
          tagsCollection={tagsCollection}
          onClickAddTag={handleOpenTagsMenu}
          onClickDeleteTag={handleDeleteTagsResources}
        />

        <TagsMenu
          anchorEl={anchorEl}
          category={category}
          selectedTagIds={tagIds}
          tagsCollection={tagsCollection}
          onClose={closeTagsMenu}
          onClickAddTag={handleCreateTagsResources}
          onClickDeleteTagFromMenu={showDeleteTagModal}
          onClickDeleteTagFromList={handleDeleteTagsResources}
          onSubmitTagUpsert={handleSubmitTagsUpsert}
        />
      </Grid>
    </Grid>
  );
};

export default SidebarTags;
