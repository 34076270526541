import BoxCircle from '@/components/ui/BoxCircle';
import Chip from '@/components/ui/Chip';
import Timeline from '@/components/ui/timeline/Timeline';
import { DATE_FORMATS } from '@/constants/app/date';
import type { TransactionDetail } from '@/types';
import { useModal } from '@bitstopco/bitstop-theme';
import { formatDate } from '@bitstopco/bitstop-theme/helpers';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import RefreshIcon from '@mui/icons-material/Refresh';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import StopIcon from '@mui/icons-material/Stop';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';

type TransactionStatusLogTabProps = {
  transaction: TransactionDetail;
};

const TransactionStatusLogEmpty = () => {
  return (
    <Stack py={6} gap={0} textAlign="center">
      <Typography variant="h5" color="text.secondary">
        No data available
      </Typography>
    </Stack>
  );
};

const TransactionStatusLog = ({ transaction }: TransactionStatusLogTabProps) => {
  const { show } = useModal();

  const handleUnfreezeTransaction = () => {
    show('unfreeze-transaction', { id: transaction.id, reason: getReason() });
  };

  const handleMarkAsRefund = () => {
    show('refund-transaction', { id: transaction.id, reason: getReason() });
  };

  const icon = (
    <BoxCircle sx={{ background: 'rgba(225, 227, 234, 0.9)' }} size={24}>
      <StopIcon sx={{ color: 'error.main', fontSize: 13 }} />
    </BoxCircle>
  );

  const getAuditIcon = (stage: string) => {
    switch (stage) {
      case 'partial':
      case 'batched':
      case 'sending':
      case 'unfrozen':
        return <CheckCircleIcon sx={{ color: 'info.main', fontSize: 16, ml: '7px' }} />;
      case 'completed':
        return <CheckCircleIcon sx={{ color: 'success.main', fontSize: 16, ml: '7px' }} />;
      case 'frozen':
        return <PauseCircleIcon sx={{ color: '#666', fontSize: 16, ml: '7px' }} />;
      case 'under_review':
        return <PauseCircleIcon sx={{ color: 'warning.main', fontSize: 16, ml: '7px' }} />;
      case 'refunded':
        return <ReplayCircleFilledIcon sx={{ color: 'success.main', fontSize: 16, ml: '7px' }} />;
      default:
        return <></>;
    }
  };

  const getReason = () => {
    let frozen_reason: string | null = null;
    const sale_audit = transaction.sale_audits.find((audit) => audit.stage === 'frozen');
    if (!sale_audit) return null;
    if (sale_audit.data?.triggers && sale_audit.data.triggers.length > 0) {
      frozen_reason = sale_audit.data?.triggers.map((t) => t.rule_name).join(', ');
    }
    return frozen_reason;
  };

  const getTitleText = (stage: string) => {
    switch (stage) {
      case 'partial':
        return 'Transaction created';
      case 'batched':
        return 'Transaction batched';
      case 'sending':
        return 'Transaction sending';
      case 'unfrozen':
        return 'Transaction released';
      case 'under_review':
        return 'Transaction under review';
      case 'refunded':
        return 'Transaction refunded';
      case 'completed':
        return 'Transaction completed';
      case 'frozen':
        return 'Transaction frozen';
      default:
        return '';
    }
  };

  const items = useMemo(() => {
    const sorted_audits = transaction.sale_audits.toSorted(
      (a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
    );

    return sorted_audits.map((sale_audit, index) => {
      const next_audit = index < sorted_audits.length ? sorted_audits[index + 1] : null;
      const frozen_reason = getReason();

      return {
        icon,
        name: 'test',
        content: (
          <Stack direction="column" alignItems={'flex-start'} sx={{ pb: 3 }}>
            <Stack direction="row" justifyContent={'space-between'} sx={{ width: '100%' }}>
              <Typography variant="body2" color="text.primary" fontSize={14} sx={{ width: '40%' }}>
                {getTitleText(sale_audit.stage)}
              </Typography>
              <Typography variant="body2" color="text.secondary" fontSize={12}>
                {formatDate(sale_audit.updated_at, DATE_FORMATS.dateHoursLocal)}
              </Typography>
            </Stack>
            {sale_audit.stage === 'frozen' && frozen_reason && (
              <Typography
                variant="body2"
                color="text.secondary"
                fontSize={12}
                fontWeight={'normal'}
              >
                Reason: {frozen_reason}
              </Typography>
            )}
            <Chip
              label={
                <Stack direction="row" alignItems="center">
                  {next_audit && (
                    <>
                      <Stack direction="row" alignItems="center" sx={{ opacity: 0.3 }}>
                        <Typography variant="body2" color="text.primary">
                          {next_audit!.stage.replaceAll('_', ' ')}
                        </Typography>
                        {getAuditIcon(next_audit!.stage)}
                      </Stack>
                      <ArrowForwardIcon sx={{ ml: 2, mr: 2, fontSize: 14 }} />
                    </>
                  )}
                  <Typography variant="body2" color="text.primary">
                    {sale_audit.stage.replaceAll('_', ' ')}
                  </Typography>
                  {getAuditIcon(sale_audit.stage)}
                </Stack>
              }
              sx={{ mt: '8px', mb: '12px', textTransform: 'capitalize' }}
            />
            {index === 0 && sale_audit.stage === 'under_review' && (
              <Stack direction="row" spacing={1}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleUnfreezeTransaction}
                >
                  Unfreeze transaction
                </Button>
                <Button variant="outlined" size="small" onClick={handleMarkAsRefund}>
                  Mark as a refund <RefreshIcon sx={{ fontSize: 16, ml: '7px' }} />
                </Button>
              </Stack>
            )}
          </Stack>
        ),
      };
    });
  }, [transaction]);

  return (
    <Box pt={3}>
      <Timeline
        items={items}
        dotProps={{
          sx: {
            p: 0,
            border: '0px',
          },
        }}
      />
    </Box>
  );
};

const TransactionStatusLogTab = ({ transaction }: TransactionStatusLogTabProps) =>
  transaction.sale_audits.length > 0 ? (
    <TransactionStatusLog transaction={transaction} />
  ) : (
    <TransactionStatusLogEmpty />
  );

export default TransactionStatusLogTab;
