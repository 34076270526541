import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';

import type {
  ApiListingRequest,
  TablePageSelectorProps,
  TableWrapperRef,
} from '@bitstopco/bitstop-theme';
import { TablePageSelector, TableWrapper, useModal } from '@bitstopco/bitstop-theme';
import { EMPTY_DATE_RANGE } from '@bitstopco/bitstop-theme/constants';

import omit from 'lodash/omit';
import { useParams } from 'react-router';

import SalesStatsCards from '@/components/ui/SalesStatsCards';
import CardTable from '@/components/ui/card/CardTable';
import { appendToFilters } from '@/helpers/api';
import {
  useGetTransactionsExportMutation,
  useGetTransactionsQuery,
  useGetTransactionsStatsQuery,
} from '@/store/api/services/transactions/transactions';

import { DEFAULT_PAGINATION } from '@/constants/api';
import { CUSTOMER_SALES_COLUMNS } from '@/constants/app/customers/customer';
import { MACHINE_SALES_STATS } from '@/constants/app/machine';

import type { TransactionsSale } from '@/types';

const CustomerTransactions = () => {
  const { id = '0', saleId } = useParams();
  const { show } = useModal();

  const [exportMutation] = useGetTransactionsExportMutation();

  const [payload, setPayload] = useState<ApiListingRequest>();

  const tableRef = useRef<TableWrapperRef>(null);

  /**
   * Computed payload with the approval filter addition
   */
  const computedPayload = useMemo(() => {
    if (!payload) {
      return skipToken;
    }

    const customerFilter = {
      operator: 'equal',
      property: 'customer_id',
      value: id,
    };

    return appendToFilters(payload, [customerFilter]);
  }, [payload, id]);

  const statsPayload = useMemo(() => {
    if (typeof computedPayload === 'symbol') {
      return computedPayload;
    }

    return omit(computedPayload, ['pagination', 'page', 'items_per_page']);
  }, [computedPayload]);

  const {
    data: {
      data = [],
      meta: { pagination: metaPagination = DEFAULT_PAGINATION } = {},
    } = {},
    isFetching,
  } = useGetTransactionsQuery(computedPayload);

  const {
    data: { data: stats } = {},
    isLoading: isLoadingTransactionsStats,
  } = useGetTransactionsStatsQuery(statsPayload);

  useEffect(() => {
    if (saleId) {
      handleRow({ id: saleId } as TransactionsSale);
    }
  }, []);

  const handleRow = ({ id }: TransactionsSale) => {
    show('transaction-details', {
      data: {
        id,
        hideCustomerLink: true,
      },
    });
  };

  const handleExport = (type: string) => {
    if (!payload) {
      return;
    }
    // Use the getExportFile helper to export the Table data using the current payload
    tableRef.current?.getExportFile({
      type,
      mutation: exportMutation,
      overridePayload: computedPayload as ApiListingRequest,
      fileName: 'Nonce-Atms-Report',
    });
  };

  // Custom render Page Selector, this is necessary since we are using a custom SalesStatsCards component
  const renderPageSelector = useCallback(
    (props: TablePageSelectorProps) => (
      <>
        <SalesStatsCards
          sx={{ mx: 3, mb: 2 }}
          stats={stats}
          mapData={MACHINE_SALES_STATS}
          isLoading={isLoadingTransactionsStats || !payload}
        />
        <TablePageSelector {...props} />
      </>
    ),
    [stats, payload, isLoadingTransactionsStats],
  );

  return (
    <CardTable sx={{ m: 0 }}>
      <TableWrapper<TransactionsSale>
        data={data}
        ref={tableRef}
        isLoading={isFetching || !payload}
        dateRangeFilterKey="created_at"
        sortByDefaultValue="created_at"
        columns={CUSTOMER_SALES_COLUMNS}
        dateRangeDefaultValue={EMPTY_DATE_RANGE}
        renderPageSelector={renderPageSelector}
        totalPages={metaPagination?.total_pages}
        totalItems={metaPagination?.total_items}
        hideActionMenu
        tableProps={{
          placeholder: {
            empty: {
              title: 'No sales found',
              content:
                "We can't find any sale that matches your search, please try a different request",
            },
          },
        }}
        tableToolbarProps={{
          searchPlaceholder: 'Search by Transaction ID...',
        }}
        onChange={setPayload}
        onRowClick={handleRow}
        onActionMenuItemClick={handleExport}
      />
    </CardTable>
  );
};

export default CustomerTransactions;
