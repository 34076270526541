import { CONSTANTS, ClickableText, TableColumnPie } from '@bitstopco/bitstop-theme';
import type {
  ApiListingRequest,
  CardInfoProps,
  Columns,
  TableToolbarFilterConfig,
} from '@bitstopco/bitstop-theme';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MonitorIcon from '@mui/icons-material/Monitor';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import type { ChipProps } from '@mui/material';

import MachinesDownIcon from '@/assets/machines-down-icon.svg?react';
import MachinesAtms from '@/components/machines/tabs/atms/MachinesAtms';
import MachinesStatus from '@/components/machines/tabs/atms/tableColumns/MachinesStatus';
import MachinesCashLogs from '@/components/machines/tabs/cashLogs/MachinesCashLogs';
import MachinesCashLogsBills from '@/components/machines/tabs/cashLogs/tableColumns/MachinesCashLogsBills';
import MachinesCashLogsMachine from '@/components/machines/tabs/cashLogs/tableColumns/MachinesCashLogsMachine';

import TableColumnDate from '@/components/ui/table/TableColumnDate';
import TableColumnMachine from '@/components/ui/table/TableColumnMachine';
import TableHeaderTooltipIcon from '@/components/ui/table/TableHeaderDateTooltipIcon';

import { validateFilterById } from '@/helpers/api';

import { DEFAULT_MACHINE_TIME_LEGEND } from './date';

import TagList from '@/components/ui/TagList';
import type { InstalledMachine, Machine, MachinesCashLog, WithTags } from '@/types';

/**
 * Machines Panels configuration (Tabs)
 * * PANELS: tab names
 * * PANELS_ROUTES: tab routes
 * * PANELS_COMPONENTS: tab related components
 */
export const PANELS = ['Machines', 'Cash Logs'];
export const PANELS_ROUTES = ['/machines/atms', '/machines/cash-logs'];
export const PANELS_COMPONENTS = [MachinesAtms, MachinesCashLogs];

/**
 * Machines top card configuration
 */
export const MACHINES_CARDS = {
  total_online: {
    title: 'Machines Online',
    icon: MonitorIcon,
    showFloatingIcon: false,
  },
  total_down: {
    title: 'Machines down',
    icon: MonitorIcon,
    showFloatingIcon: true,
    floatingIcon: (() => <MachinesDownIcon />) as unknown as CardInfoProps['floatingIcon'],
  },
  total_machines: {
    title: 'Total Machines',
    icon: SyncAltIcon,
    showFloatingIcon: false,
  },
  cash_total: {
    title: 'Cash Total',
    icon: AttachMoneyIcon,
    showFloatingIcon: false,
  },
};

/**
 * Machines columns configuration: The columns configuration will be used to generate the table with the desired data
 * * You can learn more about the Table columns configuration in the Theme Live docs: https://github.com/bitstopco/bitstop-theme#live-docs
 */
export const MACHINES_COLUMNS: Columns<WithTags<Machine>> = {
  defaultValue: '-',
  labelSx: {
    whiteSpace: 'nowrap',
  },
  valueSx: {
    whiteSpace: 'nowrap',
  },
  items: [
    {
      value: 'id',
      label: 'Machine',
      valueMapper: (_, data) => <TableColumnMachine data={data} />,
      valueSx: {
        '& h6': {
          maxWidth: 400,
          whiteSpace: 'normal',
          wordWrap: 'break-word',
        },
      },
    },
    {
      value: 'status',
      label: 'Status',
      valueMapper: (value: Machine['status']) => value && <MachinesStatus value={value} />,
    },
    {
      value: 'serial',
      label: 'Serial',
    },
    {
      value: 'pick_up_id',
      label: 'Pickup ID',
    },
    {
      value: 'total_worth_of_bills',
      label: 'Cash Total',
      valueMapper: (value: Machine['total_worth_of_bills'] = 0) => `$${value}`,
      valueSx: {
        textAlign: 'right',
      },
    },
    {
      value: 'total_amount_of_bills',
      label: 'Cash Box',
      valueMapper: (value: Machine['total_amount_of_bills'], data) => (
        <TableColumnPie {...{ value, data }} />
      ),
    },
    {
      label: 'Tags',
      labelSx: {
        minWidth: '250px',
      },
      valueMapper: (_, { tag_ids, tags }) => (
        <TagList selectedTagIds={tag_ids} tagsCollection={tags} />
      ),
      hideSort: true,
    },
    {
      value: 'last_restart',
      label: 'Last Restart',
      valueMapper: (_, data) => data?.last_restart_humanized,
    },
    {
      value: 'last_seen',
      label: 'Last Seen',
      valueMapper: (_, data) => data?.last_seen_humanized,
    },
    {
      value: 'last_sale_created_at',
      label: 'Last Sale',
      valueMapper: (_, data) => data?.last_sale_created_at_humanized,
    },
  ],
};

/**
 * Cash Logs columns configuration: The columns configuration will be used to generate the table with the desired data
 * * You can learn more about the Table columns configuration in the Theme Live docs: https://github.com/bitstopco/bitstop-theme#live-docs
 */
export const CASH_LOGS_COLUMNS: Columns<MachinesCashLog> = {
  defaultValue: '-',
  labelSx: {
    whiteSpace: 'nowrap',
  },
  valueSx: {
    whiteSpace: 'nowrap',
  },
  items: [
    {
      value: 'id',
      label: 'Cash Log ID',
      valueMapper: (value: MachinesCashLog['id']) =>
        value && (
          <ClickableText name="ID" text={value.toString()} clipboardValue={value.toString()} />
        ),
    },
    {
      value: 'atm_id',
      label: 'Machine ID',
      valueMapper: (value: MachinesCashLog['atm_id']) =>
        value && (
          <ClickableText
            name="Machine ID"
            text={value.toString()}
            clipboardValue={value.toString()}
            overrideTooltipLinkText="Go to Machine"
            to={`${window.location.origin}/machine/${value}`}
          />
        ),
    },
    {
      value: 'atm.name',
      label: 'Machine',
      valueMapper: (name: string, { full_address: address }) => (
        <MachinesCashLogsMachine {...{ name, address }} />
      ),
    },
    {
      value: 'created_at',
      label: 'Pulled On',
      labelMapper: (value: string) => (
        <TableHeaderTooltipIcon label={value} tooltipTitle={DEFAULT_MACHINE_TIME_LEGEND} />
      ),
      valueMapper: (value: MachinesCashLog['created_at'], { local_created_at, local_time_zone }) =>
        value && (
          <TableColumnDate
            value={value}
            options={{
              timeZone: 'UTC',
            }}
            auxiliaryDate={{
              value: local_created_at,
              options: {
                timeZone: local_time_zone,
                normalizeAsUTC: false,
                performUtcToZonedTime: false,
              },
            }}
          />
        ),
    },
    {
      value: 'total_worth_of_bills',
      label: 'Bills Worth',
      valueMapper: (value: MachinesCashLog['total_worth_of_bills'] = 0) => `$${value}`,
      labelSx: {
        textAlign: 'center',
        whiteSpace: 'nowrap',
      },
      valueSx: {
        textAlign: 'center',
        whiteSpace: 'nowrap',
        pr: 5,
      },
    },
    {
      value: 'one',
      label: 'Bills',
      hideSort: true,
      valueMapper: (value: MachinesCashLog['one'], data) => (
        <MachinesCashLogsBills {...{ value: value?.toString(), data }} />
      ),
    },
  ],
};

export const MACHINE_CASH_LOGS_COLUMNS: Columns<MachinesCashLog> = {
  ...CASH_LOGS_COLUMNS,
  items: CASH_LOGS_COLUMNS.items.filter(
    ({ value = '' }) => !['atm_id', 'atm.name'].includes(value),
  ),
};

/**
 * Cash Logs bills configuration:
 * * This is used to generate the Cash Logs bills column table
 */
export const CASH_LOGS_BILLS = [
  {
    id: 'one',
    label: '$1',
  },
  {
    id: 'two',
    label: '$2',
  },
  {
    id: 'five',
    label: '$5',
  },
  {
    id: 'ten',
    label: '$10',
  },
  {
    id: 'twenty',
    label: '$20',
  },
  {
    id: 'fifty',
    label: '$50',
  },
  {
    id: 'hundred',
    label: '$100',
  },
];

/**
 * Machines filters configuration
 * * MACHINES_FILTERS_CONFIG: Table filters configuration. You can learn more about the Theme Table filters here:
 * * https://github.com/bitstopco/bitstop-theme#live-docs
 */
export const MACHINES_FILTERS_CONFIG: TableToolbarFilterConfig[] = [
  {
    property: 'id',
    type: 'select-ids',
    placeholder: 'Any Machine',
    inputPlaceholder: 'Machine ID',
    label: 'Machine ID',
    validate: validateFilterById('machine'),
    mapperSelectedValue: (value: string) => `Machine ${value}`,
    mapperSelectedValues: (count: number) => `Machine ID (${count})`,
  },
  {
    type: 'select',
    property: 'status',
    showFooter: true,
    hideApplyButton: true,
    placeholder: 'Status',
    clearText: 'Clear filter',
    label: 'Status',
    options: [],
    multiple: true,
  },
  {
    property: 'pick_up_id',
    label: 'Pick-up ID',
    type: 'select-ids',
    placeholder: 'Any Pick-up ID',
    inputPlaceholder: 'Pick-up ID',
    subtitleText: '',
    multiple: false,
    validate: (value: string) => {
      // We want to apply the custom filter for the pick_up_id validation
      const filters: ApiListingRequest = {
        filter: {
          operator: 'and',
          operands: [
            {
              operator: 'equal',
              property: 'pick_up_id',
              value: value,
            },
          ],
        },
        pagination: {
          current_page: 1,
          items_per_page: 1,
          sort_ascending: false,
          sort_by: 'id',
        },
      };

      return validateFilterById('pick_up_id')(filters);
    },
    mapperSelectedValue: (value: string) => `Machine ${value}`,
    mapperSelectedValues: (count: number) => `Machine ID (${count})`,
  },
  {
    property: 'tag_ids',
    operator: 'contains',
    type: 'select',
    label: 'Tags',
    placeholder: 'Tags',
    searchable: true,
    multiple: true,
    showFooter: true,
    options: [],
    searchPlaceholder: 'Search by tag name',
  },
  {
    property: 'last_restart',
    operator: 'less_than_or_equal',
    type: 'select-timeframe',
    label: 'Select a time frame',
    placeholder: 'Last Restart',
    showFooter: true,
    hideApplyButton: true,
    options: [...CONSTANTS.TIME_FRAME],
  },
  {
    property: 'last_seen',
    type: 'select-timeframe',
    label: 'Select a time frame',
    placeholder: 'Last Seen',
    showFooter: true,
    hideApplyButton: true,
    options: [...CONSTANTS.TIME_FRAME],
  },
];

/**
 * Machines command statuses used in the Machines Table column status component
 */
export const MACHINES_COMMAND_STATUSES: Record<
  string,
  {
    label: string;
    color: ChipProps['color'];
  }
> = {
  ok: {
    color: 'success',
    label: 'Ok',
  },
  maintenance_mode: {
    color: 'error',
    label: 'Maintenance Mode',
  },
};

export const MACHINES_COMMAND_STATUSES_VALUES = Object.keys(MACHINES_COMMAND_STATUSES).map(
  (key) => {
    const { label } = MACHINES_COMMAND_STATUSES[key];

    return {
      key,
      value: key,
      label: label,
    };
  },
);

export const MACHINES_MULTI_SELECT_CONFIG = [
  {
    label: 'Add Tag',
  },
  {
    label: 'Remove Tag',
  },
];

export const INSTALLED_MACHINES_COLUMNS: Columns<InstalledMachine> = {
  defaultValue: '-',
  labelSx: {
    whiteSpace: 'nowrap',
  },
  valueSx: {
    whiteSpace: 'nowrap',
  },
  items: [
    {
      label: 'Machine',
      valueMapper: (_, data) => <TableColumnMachine data={data as Machine} />,
      valueSx: {
        '& h6': {
          maxWidth: 400,
          whiteSpace: 'normal',
          wordWrap: 'break-word',
        },
      },
    },
    {
      value: 'status',
      label: 'Status',
      valueMapper: (value: Machine['status']) => value && <MachinesStatus value={value} />,
    },
    {
      value: 'serial',
      label: 'Serial',
    },
    {
      value: 'machine_id',
      label: 'Global ID',
    },
  ],
};
