import TabsPanels from '@/components/tabs/TabsPanels';

import { PANELS, PANELS_ROUTES } from '@/constants/app/transaction';
import TransactionDetailsTab from './TransactionDetailsTab';
import TransactionStatusLogTab from './TransactionStatusLogTab';

import type { Tag, TransactionDetail, TransactionImageSource } from '@/types';

interface TransactionTabsPanelsProps {
  transaction: TransactionDetail;
  images: TransactionImageSource[];
  hideAtmValue: boolean;
  hideCustomerLink: boolean;
  hide: () => void;
  tagIds: string[];
  tagsCollection: Tag[];
  resourceId: string;
}

const TransactionTabsPanels = ({
  transaction,
  images,
  hideAtmValue,
  hideCustomerLink,
  hide,
  tagIds,
  tagsCollection,
  resourceId,
}: TransactionTabsPanelsProps) => (
  <TabsPanels
    name="transactions"
    panels={PANELS}
    routes={PANELS_ROUTES}
    components={[
      () => (
        <TransactionDetailsTab
          transaction={transaction}
          images={images}
          hideAtmValue={hideAtmValue}
          hideCustomerLink={hideCustomerLink}
          hide={hide}
          tagIds={tagIds}
          tagsCollection={tagsCollection}
          resourceId={resourceId}
        />
      ),
      () => <TransactionStatusLogTab transaction={transaction} />,
    ]}
  />
);

export default TransactionTabsPanels;
