import api from '@/store/api';
import { athena } from '@/store/api/instances';
import type { ApiResponse } from '@bitstopco/bitstop-theme';

import type {
  CreateTagRequest,
  GetTagsRequest,
  Tag,
  TagConstraints,
  TagStats,
  TagsResourcesAssociationRequest,
  UpdateTagRequest,
} from '@/types';

/**
 * Tags RTK-Query API
 * For more information about RTK-Query see
 * * https://redux-toolkit.js.org/rtk-query/overview
 */
export const tagsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTagStatsById: build.query<ApiResponse<TagStats>, string>({
      query: (id) => athena({ url: `v2/Tag/${id}/stats` }),
    }),
    getAllTagsByTerm: build.query<ApiResponse<Tag[]>, GetTagsRequest>({
      query: ({ term = '', category }) => {
        const data = {
          pagination: {
            sort_by: 'id',
            current_page: 1,
            items_per_page: 500,
            sort_ascending: true,
          },
          filter: {
            operands: [
              { operator: 'contains', property: 'name', value: term },
              { operator: 'equal', property: 'category', value: category },
            ],
            operator: 'and',
          },
        };
        return athena({ url: 'v2/tags', method: 'POST', data });
      },
      providesTags: ['TagsByTerm'],
    }),
    getTagConstraints: build.query<ApiResponse<TagConstraints>, void>({
      query: () => athena({ url: 'v2/tag/constraints' }),
    }),
    createTag: build.mutation<ApiResponse<string>, CreateTagRequest>({
      query: (data) => athena({ url: 'v2/tag', method: 'POST', data }),
      invalidatesTags: [
        'TagsByTerm',
        'Customer',
        'Machine',
        'Location',
        'Transaction',
        { type: 'Customers' as const, id: 'LIST' },
        { type: 'Machines' as const, id: 'LIST' },
        { type: 'Locations' as const, id: 'LIST' },
        { type: 'Transactions' as const, id: 'LIST' },
      ],
    }),
    updateTag: build.mutation<ApiResponse<string>, UpdateTagRequest>({
      query: ({ id, ...data }) => athena({ url: `v2/tag/${id}`, method: 'PUT', data }),
      invalidatesTags: [
        'TagsByTerm',
        'Customer',
        'Machine',
        'Location',
        'Transaction',
        { type: 'Customers' as const, id: 'LIST' },
        { type: 'Machines' as const, id: 'LIST' },
        { type: 'Locations' as const, id: 'LIST' },
        { type: 'Transactions' as const, id: 'LIST' },
      ],
    }),
    deleteTag: build.mutation<ApiResponse<string>, string>({
      query: (id) => athena({ url: `v2/tag/${id}`, method: 'DELETE' }),
      invalidatesTags: [
        'TagsByTerm',
        'Customer',
        'Machine',
        'Location',
        'Transaction',
        { type: 'Customers' as const, id: 'LIST' },
        { type: 'Machines' as const, id: 'LIST' },
        { type: 'Locations' as const, id: 'LIST' },
        { type: 'Transactions' as const, id: 'LIST' },
      ],
    }),
    createTagsResources: build.mutation<ApiResponse<string>, TagsResourcesAssociationRequest>({
      query: (data) => athena({ url: 'v2/tags/resources', method: 'POST', data }),
      invalidatesTags: [
        'TagsByTerm',
        'Customer',
        'Machine',
        'Location',
        'Transaction',
        { type: 'Customers' as const, id: 'LIST' },
        { type: 'Machines' as const, id: 'LIST' },
        { type: 'Locations' as const, id: 'LIST' },
        { type: 'Transactions' as const, id: 'LIST' },
      ],
    }),
    deleteTagsResources: build.mutation<ApiResponse<string>, TagsResourcesAssociationRequest>({
      query: (data) => athena({ url: 'v2/tags/resources', method: 'DELETE', data }),
      invalidatesTags: [
        'TagsByTerm',
        'Customer',
        'Machine',
        'Location',
        'Transaction',
        { type: 'Customers' as const, id: 'LIST' },
        { type: 'Machines' as const, id: 'LIST' },
        { type: 'Locations' as const, id: 'LIST' },
        { type: 'Transactions' as const, id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useGetTagStatsByIdQuery,
  useGetAllTagsByTermQuery,
  useGetTagConstraintsQuery,
  useCreateTagMutation,
  useUpdateTagMutation,
  useDeleteTagMutation,
  useCreateTagsResourcesMutation,
  useDeleteTagsResourcesMutation,
} = tagsApi;

export const {
  endpoints: {
    getTagStatsById,
    getAllTagsByTerm,
    getTagConstraints,
    createTag,
    updateTag,
    deleteTag,
    createTagsResources,
    deleteTagsResources,
  },
} = tagsApi;
